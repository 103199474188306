import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import UnpairModal from './UnpairModal'
import UnregisterModal from './UnregisterModal'
import { requirePermissions } from '../../../common/helpers/helpers'
import SockMigration from './SockMigration'
import GenerateSleepData from './GenerateSleepData'
import { deviceTypeSock3, deviceTypeSock3Sleep, deviceTypeSock4 } from '../../../../constants/deviceConstants'
import GenerateSevenDaysData from './GenerateSevenDaysData'
import DeleteSevenDaysData from './DeleteSevenDaysData'
import permissions from '../../../../constants/permissions'


const DeviceActions = ({
    authUser,
    currentDevice,
    hideUnpairModal,
    hideUnregisterModal,
    isUnpairModalShown,
    isUnregisterModalShown,
    showUnpairModal,
    showUnregisterModal,
    unpairSensor,
    unregisterDeviceFromUser,
  }) => {

  let migrationTargets = [];

  switch (currentDevice.type) {
    case deviceTypeSock3Sleep.type:
       migrationTargets = [{
          name: deviceTypeSock3.name,
          type: deviceTypeSock3.type,
        }, {
          name: deviceTypeSock4.name,
          type: deviceTypeSock4.type,
        },
      ]
      break;
    case deviceTypeSock3.type:
       migrationTargets = [{
          name: deviceTypeSock3Sleep.name,
          type: deviceTypeSock3Sleep.type,
        }, {
          name: deviceTypeSock4.name,
          type: deviceTypeSock4.type,
        },
      ]
      break;
    case deviceTypeSock4.type:
      migrationTargets = [{
          name: deviceTypeSock3Sleep.name,
          type: deviceTypeSock3Sleep.type,
        }, {
          name: deviceTypeSock3.name,
          type: deviceTypeSock3.type,
        },
      ]
      break;
    default:
      migrationTargets = []
  }

  return (
    <Grid className='deviceActionsContainer' container direction="column" justifyContent='flex-start'>
      <Grid item>
        <Paper elevation={0} className='sectionTitleContainer'>
            <h1 className='sectionTitle'>Device Actions</h1>
        </Paper>
      </Grid>
      <Grid container direction="row">
        <Grid className="gridItem" item xs={10}>
        {requirePermissions([permissions.viewDeviceActions], authUser) ?
          <Paper>
              <dl className="defList">
                {currentDevice.type === 'smartSock' && requirePermissions([permissions.editDeviceActions], authUser) ?
                  <>
                      <dt>Sensor Pairing</dt>
                      <dd><UnpairModal
                          currentDevice={currentDevice}
                          hideUnpairModal={hideUnpairModal}
                          isUnpairModalShown={isUnpairModalShown}
                          showUnpairModal={showUnpairModal}
                          unpairSensor={unpairSensor}
                      /></dd>
                  </>
                  : <><dt>Sensor Pairing</dt><dd>No Actions Avaialble</dd></>}
                  {!currentDevice.type.includes('Cam') && requirePermissions([permissions.deleteDeviceActions], authUser) ?
                  <>
                      <dt>Device Unregistration</dt>
                      <dd>
                      <UnregisterModal
                          currentDevice={currentDevice}
                          hideUnregisterModal={hideUnregisterModal}
                          isUnregisterModalShown={isUnregisterModalShown}
                          showUnregisterModal={showUnregisterModal}
                          unregisterDeviceFromUser={unregisterDeviceFromUser}
                      /></dd>
                  </>
                  : <><dt>Device Unregistration</dt><dd>No Actions Avaialble</dd></>}
                  {(['smartSock3', 'smartSock3Sleep', 'smartSock4'].includes(currentDevice.type)) && requirePermissions([permissions.editDeviceActions], authUser) ?
                  <>
                    <dt>Device Conversion</dt>
                      <dd>
                        <SockMigration
                          currentDevice={currentDevice}
                          migrationTargets={migrationTargets}
                        />
                      </dd>
                </>
              : <><dt>Device Conversion</dt><dd>No Actions Available</dd></>}
              {(['smartSock3', 'smartSock3Sleep'].includes(currentDevice.type)) && requirePermissions([permissions.editDeviceActions], authUser) ?
                  <>
                    <dt>Generate Sleep Data</dt>
                    <dd>
                      <GenerateSleepData
                        currentDevice={currentDevice}
                      />
                    </dd>
                  </>
                  : <><dt>Generate Sleep Data</dt><dd>No Actions Available</dd></>}

                  {requirePermissions([permissions.editDeviceActions], authUser) ?
                  <>
                    <dt>Generate Seven Days Data Legend</dt>
                    {currentDevice.type.includes('Sock') ?
                    <dd>
                      <dl>
                        <dt>start_time</dt>
                        <dd>Optional session start time with timezone offset</dd>
                        <dt>sleep_length</dt>
                        <dd>Sleep length in minutes, must be increments of 10</dd>
                        <dt>temp_range</dt>
                        <dd>Skin temp in Celsius x2, ex: [72, 76] represents 36 - 38 in C</dd>
                        <dt>hr_range</dt>
                        <dd>Heart Rate range. 90 - 100 for a healthy range</dd>
                        <dt>ox_range</dt>
                        <dd>Oxygen range. 95 - 100 for a healthy range</dd>
                        <dt>sleep_positions</dt>
                        <dd>Sleep position. 0 - 15. See confluence for each value definition.</dd>
                        <dt>movement_range</dt>
                        <dd>Baby movement_range. Still/Deep Sleep: 0-5, Wiggle/Light Sleep: 6-25, Active/Awake: 25+</dd>
                      </dl>
                    </dd>
                    :
                    <dd>
                      <dl>
                        <dt>start_time</dt>
                        <dd>Optional session start time with timezone offset</dd>
                        <dt>temp_range</dt>
                        <dd>Room temp in Celsius. Default to 22.</dd>
                        <dt>humidity_range</dt>
                        <dd>Humidity in percentage. 0 - 100</dd>
                        <dt>noise_range</dt>
                        <dd>Noise range in DB</dd>
                        <dt>brightness_range</dt>
                        <dd>Brightness in lux</dd>
                      </dl>
                    </dd> }
                    <dt>Generate Seven Days Data</dt>
                    <dd>
                      <GenerateSevenDaysData
                        authUser={authUser}
                        currentDevice={currentDevice}
                      />
                    </dd>
                    <dt>Nuke Seven Days Data</dt>
                    <dd>
                      <DeleteSevenDaysData
                        authUser={authUser}
                        currentDevice={currentDevice}
                      />
                    </dd>
                  </>
                  : <><dt>Generate Seven Days Data</dt><dd>No Actions Available</dd></>}
              </dl>
          </Paper>
          : <Paper>
              <dl className="defList">
                <dt>No Actions Available</dt>
                <dd>No Actions Available</dd>
              </dl>
            </Paper>
            }
        </Grid>
      </Grid>
    </Grid>
  )
}

DeviceActions.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  currentDevice:
  PropTypes.shape({
      account: PropTypes.shape({
          email: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
      }),
      accountId: PropTypes.string,
      activatedDate: PropTypes.string,
      associatedServiceUsers: PropTypes.arrayOf(PropTypes.shape({})),
      aylaEnvironment: PropTypes.string,
      deviceId: PropTypes.string,
      dsn: PropTypes.string,
      firmwareUpdateStatus: PropTypes.string,
      firmwareVersion: PropTypes.string,
      lastOnlineTime: PropTypes.string,
      macAddress: PropTypes.string,
      oemBaseVersion: PropTypes.string,
      oemSockVersion: PropTypes.string,
      registrationDate: PropTypes.string,
      runningFirmwareVersion: PropTypes.string,
      serviceUserId: PropTypes.string,
      serviceUserIds: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string,
      userProfile: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string
      }),
  }),
  hideUnpairModal: PropTypes.func.isRequired,
  hideUnregisterModal: PropTypes.func.isRequired,
  isUnpairModalShown: PropTypes.bool.isRequired,
  isUnregisterModalShown: PropTypes.bool.isRequired,
  showUnpairModal: PropTypes.func.isRequired,
  showUnregisterModal: PropTypes.func.isRequired,
  unpairSensor: PropTypes.func.isRequired,
  unregisterDeviceFromUser: PropTypes.func.isRequired,
}

DeviceActions.defaultProps ={
  currentDevice: {
    account: {
        firstName: "",
        email: ""
    },
    ayla_created_at: "",
    aylaEnvironment: "",
    created_at: "",
    deviceId: "",
    dsn: "",
    firmwareUpdateStatus: "",
    firmwareVersion: "",
    lastOnlineTime: "",
    macAddress: "",
    oemBaseVersion: "",
    oemSockVersion: "",
    registered_at: "",
    registrationDate: "",
    runningFirmwareVersion: "",
    serviceUserIds: [],
    type: "",
    userProfile: {
        firstName: "",
        lastName: ""
    }
  }
}

export default DeviceActions
